<template>
  <div id="test-page">
    <app-box width="large">
      <template #legend>Test</template>
      <template #title>Test page</template>
      <template #intro>Test text.</template>
      <transition-group tag="ul" move-class="transition-transform duration-1000" enter-active-class="transition-transform duration-1000" enter-from-class="scale-0" enter-to-class="scale-100" leave-active-class="transition-transform duration-1000" leave-from-class="scale-100" leave-to-class="scale-0">
        <li :key="1" class="bg-red-200">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam faucibus felis vel metus sagittis fermentum. Duis eu nibh dictum, auctor elit non, mattis ipsum. Praesent sed convallis ante. In vulputate pulvinar lectus, eget malesuada nunc porta sed. Ut lorem nunc, porttitor sed scelerisque sit amet, convallis id lectus. Etiam eget porta enim. Nunc urna nulla, scelerisque feugiat tristique egestas, auctor a augue. Etiam euismod vel libero ac tincidunt. Morbi ac sollicitudin lacus. Quisque in velit auctor arcu fermentum condimentum in ac urna. Ut dapibus felis id neque scelerisque congue. Vivamus a fermentum nibh. Cras luctus urna est, vel lacinia lectus egestas eget. Proin tempor quam arcu, sed placerat velit varius in.</p>
        </li>
        <li v-if="show" :key="2" class="bg-green-200">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam faucibus felis vel metus sagittis fermentum. Duis eu nibh dictum, auctor elit non, mattis ipsum. Praesent sed convallis ante. In vulputate pulvinar lectus, eget malesuada nunc porta sed. Ut lorem nunc, porttitor sed scelerisque sit amet, convallis id lectus. Etiam eget porta enim. Nunc urna nulla, scelerisque feugiat tristique egestas, auctor a augue. Etiam euismod vel libero ac tincidunt. Morbi ac sollicitudin lacus. Quisque in velit auctor arcu fermentum condimentum in ac urna. Ut dapibus felis id neque scelerisque congue. Vivamus a fermentum nibh. Cras luctus urna est, vel lacinia lectus egestas eget. Proin tempor quam arcu, sed placerat velit varius in.</p>
        </li>
        <li :key="3" class="bg-blue-200">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam faucibus felis vel metus sagittis fermentum. Duis eu nibh dictum, auctor elit non, mattis ipsum. Praesent sed convallis ante. In vulputate pulvinar lectus, eget malesuada nunc porta sed. Ut lorem nunc, porttitor sed scelerisque sit amet, convallis id lectus. Etiam eget porta enim. Nunc urna nulla, scelerisque feugiat tristique egestas, auctor a augue. Etiam euismod vel libero ac tincidunt. Morbi ac sollicitudin lacus. Quisque in velit auctor arcu fermentum condimentum in ac urna. Ut dapibus felis id neque scelerisque congue. Vivamus a fermentum nibh. Cras luctus urna est, vel lacinia lectus egestas eget. Proin tempor quam arcu, sed placerat velit varius in.</p>
        </li>
      </transition-group>
      <hr>
      <button @click.prevent="show=!show">Animate</button>
    </app-box>
  </div>
</template>

<script>
import { Util as U } from "@/util";
import { nextTick } from "vue";
export default {
  name: "Test",
  data() {
    return {
      show: false,
    };
  },
  computed: {
    cTest() {
      return "Test";
    },
  },
  async created() {
    this.$store.commit("state", { key: "loading", value: true });
    await nextTick();
    this.$store.commit("state", { key: "loading", value: false });
  },
  methods: {
    testing() {
      console.log("Testing");
    },
  },
};
</script>
